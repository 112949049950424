"use strict";
import "../../sass/maintenance/maintenance.scss";
import Countdown from "../common/_countdown";

export default (function () {

    $(function () {
        initPage();
    });

    function initPage() {

        const diasLancamento = new Countdown("10 october 2022 23:59:59 GMT-0300");
        const dias = document.getElementById("dias");
        const horas = document.getElementById("horas");
        const minutos = document.getElementById("minutos");
        const segundos = document.getElementById("segundos");

        setInterval(() => {
            dias.innerText = diasLancamento.total.days + " dias";
            horas.innerText = diasLancamento.total.hours + "h";
            minutos.innerText = diasLancamento.total.minutes + "min";
            segundos.innerText = diasLancamento.total.seconds + "s";
        }, 1000);   
    }

}());